import React from 'react';

const ToS: React.FC = () => (
  <div className='page'>
    <h1>Terms of Service</h1>
    <p>These are the terms of service...</p>
  </div>
);

export default ToS;
