import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './components/Home';
import ToS from './components/ToS';
import Privacy from './components/Privacy';
import { Helmet } from 'react-helmet';
import AccountRemoval from './components/AccountRemoval';

const App: React.FC = () => (
  <Router>
    <Helmet>
      <title>Behavior Monster</title>
    </Helmet>
    <header>
        <div className="logo">Behavior Monster</div>
        <nav>
          <ul>
            <li><Link to="/">Home</Link></li>
            {/* <li><Link to="/about">About</Link></li> */}
          </ul>
        </nav>
    </header>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/tos" element={<ToS />} />
      <Route path="/privacy-policy" element={<Privacy />} />
      <Route path="/account-removal" element={<AccountRemoval />}></Route>
    </Routes>
    <footer>
        <p>Contact us at support@behaviormonster.com</p>
        <ul>
          <li><Link to="/tos">Terms of Service</Link></li>
          <li><Link to="/privacy-policy">Privacy Policy</Link></li>
        </ul>
    </footer>
  </Router>
);

export default App;