import React from 'react';

const AccountRemoval: React.FC = () => (
  <div className='page'>
<h1>How to Delete Your Account on Behavior Monster</h1>
    
    <p>If you wish to delete your account in the <strong>Behavior Monster</strong> app, follow the simple steps outlined below. Once you delete your account, all associated data will be removed immediately.</p>

    <h2>Steps to Delete Your Account</h2>
    <ul>
        <li>Open the <strong>Behavior Monster</strong> app.</li>
        <li>On the main screen, where the monster is displayed, click on the <strong>Settings</strong> button (gear icon).</li>
        <li>Scroll to the bottom of the <strong>Settings</strong> page.</li>
        <li>Click on <strong>Remove your account</strong> button.</li>
    </ul>

    <p>After completing these steps, your account and all associated data will be deleted permanently.</p>

    <h2>Types of Data Deleted</h2>
    <p>When you request the deletion of your account, the following data will be permanently deleted from our systems:</p>
    <ul>
        <li>User profile information</li>
        <li>App preferences and settings</li>
    </ul>
    
    <p><strong>Important:</strong> Once deleted, your data cannot be recovered.</p>

    <h2>Data Retention Periods</h2>
    <p>We do not retain any of your personal data after account deletion. All data is removed immediately upon confirming your account deletion request, and no additional retention periods apply.</p>

    <p>If you have any further questions or need assistance, please contact us at <strong>support@behaviormonster.com</strong>.</p>
</div>

);

export default AccountRemoval;
